* {
  -ms-overflow-style: none;
  overflow:hidden;
  background-image: linear-gradient(315deg, #000000 10%, #414141 60%);
  margin: 0;
  padding: 0;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

::-webkit-scrollbar {
  display: none;
}
.main {
  width: 100%;
  height: 100vh;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

 h1{
  top:1000
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color:white;
}

.firstshit {
  border: 0px solid white;
  margin-top:1%;
  margin-bottom: 2%;
  width: 60%;
  height: 100%;
  top:0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
 
}
p{
  text-align: center;
}
.login{
  margin-top:1%;
  margin-bottom: 2%;
  width: 80%;
  height: 100%;
  top:0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
a{
  text-decoration: none;
  color:white;
  width:100%;
  height:100%;
}
.center{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3em;
 
}
.center2{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  
}
.horizental{
  display: flex;

}
.center3{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
  height:60%;
  align-items: center;
  font-size: 1.3em;
 
}
.center4{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
  height:100%;
  align-items: center;
  font-size: 2em;
 
}
.horizental2{
  display: flex;
  margin:1%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width:100%;
  height: 10%;

}
.plus{
  display: flex;
  justify-content: center;
  margin-left: 5%;
  align-items: center;
  border: 1px solid white
  ;
  background-color: transparent;
  height: 25px;
  width: 25px;
  color:white;
  font-size: 1.2em;
  opacity: 0.7.2;
  transition: 0.3s;
}
.linko{
  width:50%;
  padding-right: 20%;
  font-size:1em;
}
.plus1{
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  align-items: center;
  border: 1px solid white;
  background-color: transparent;
  height: 30px;
  width: 30px;
  color:white;
  font-size: 0.5em;
  opacity: 0.7.2;
  transition: 0.3s;
}
input{
  background: transparent;
}
textarea{
  background-color: transparent;
}
.half{
  width:0%;
  font-size:0.5em;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  /* background-color: #000000cc; */
}
.link{
  font-size: 2em;
  margin-bottom: 5%;
  color:white;
}
button{
 width: 50%;
 height:10%;
 margin-bottom: 12%;
 border: 1px solid white;
 background-color: transparent;
 color:white;
 font-size: 1.5em;
 opacity: 0.7.2;
 transition: 0.3s;
}
#sgat{
  border:none;
  transition: 0.3s;
  z-index: 1;
}
#sgat:hover{
  cursor: pointer;
  font-size: 2em;
  height:100%;
}
.changed{
  text-align: center;
vertical-align: middle;
line-height: 90px;
}
button:hover{
  cursor: pointer;
  font-size: 1em;
  opacity:1;
  background-color: rgba(0,0,0,.4);
}